import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {ElementData} from '../../editor.app.types';
import {RootWidgetComponentIds} from '../../../components/rootWidget/config/constants';
import {CtaButtonsWidgetComponentIds} from '../../../components/ctaButtonsWidget/config/constants';
import {SocialShareWidgetComponentIds} from '../../../components/socialShareWidget/config/constants';
import {getRole} from '../../manifest/global-design/getRole';
import {ELEMENTS_PANEL_CATEGORIES_IDS as CATEGORIES_IDS} from './constants';
import {PriceWidgetComponentIds} from '../../../components/priceWidget/config/constants';

export const getElementsData = (t: ILocaleKeys, isMS3: boolean, priceLangType: string): ElementData[] => {
  /* istanbul ignore next reason: omrigr needs to fix flowAPI mock experiments */
  return elementsData(t, isMS3, priceLangType);
};

export const elementsData = (t: ILocaleKeys, isMS3: boolean, priceLangType: string): ElementData[] => {
  const elementsPanelTranslations = t.productPage.productPageWidget.elements;
  const elementData = [
    {
      label: elementsPanelTranslations.productName.label(),
      identifier: {role: getRole(RootWidgetComponentIds.ProductTitle)},
      categoryId: CATEGORIES_IDS.info,
      index: 0,
    },
    {
      label: elementsPanelTranslations.gallery.label(),
      identifier: {role: getRole(RootWidgetComponentIds.Gallery)},
      categoryId: CATEGORIES_IDS.info,
      index: 1,
      tooltipData: {
        content: elementsPanelTranslations.gallery.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.description.label(),
      identifier: {role: getRole(RootWidgetComponentIds.CollapsibleDescription)},
      categoryId: CATEGORIES_IDS.info,
      index: 3,
    },
    {
      label: elementsPanelTranslations.sku.label(),
      identifier: {role: getRole(RootWidgetComponentIds.Sku)},
      categoryId: CATEGORIES_IDS.info,
      index: 4,
      tooltipData: {
        content: elementsPanelTranslations.sku.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.productOptions.label(),
      identifier: {role: getRole(RootWidgetComponentIds.OptionsNew)},
      categoryId: CATEGORIES_IDS.info,
      index: 5,
      tooltipData: {
        content: elementsPanelTranslations.productOptions.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.quantity.label(),
      identifier: {role: getRole(RootWidgetComponentIds.Quantity)},
      categoryId: CATEGORIES_IDS.info,
      index: 6,
    },
    {
      label: elementsPanelTranslations.customText.label(),
      identifier: {role: getRole(RootWidgetComponentIds.CustomTextFields)},
      categoryId: CATEGORIES_IDS.info,
      index: 8,
      tooltipData: {
        content: elementsPanelTranslations.customText.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.subscriptions.label(),
      identifier: {role: getRole(RootWidgetComponentIds.Subscription)},
      categoryId: CATEGORIES_IDS.info,
      index: 9,
      tooltipData: {
        content: elementsPanelTranslations.subscriptions.tooltip(),
      },
    },
    {
      label: elementsPanelTranslations.infoSections.label(),
      identifier: {role: getRole(RootWidgetComponentIds.Info)},
      categoryId: CATEGORIES_IDS.info,
      index: 10,
      tooltipData: {
        content: elementsPanelTranslations.infoSections.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.finalPrice.label(),
      identifier: {role: getRole(PriceWidgetComponentIds[`ActualPrice${priceLangType}`])},
      categoryId: CATEGORIES_IDS.pricing,
      index: 0,
    },
    {
      label: elementsPanelTranslations.fromLabel.label(),
      identifier: {role: getRole(PriceWidgetComponentIds[`From${priceLangType}`])},
      categoryId: CATEGORIES_IDS.pricing,
      index: 1,
      tooltipData: {
        content: elementsPanelTranslations.fromLabel.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.strikethroughPrice.label(),
      identifier: {role: getRole(PriceWidgetComponentIds[`OriginalPrice${priceLangType}`])},
      categoryId: CATEGORIES_IDS.pricing,
      index: 2,
      tooltipData: {
        content: elementsPanelTranslations.strikethroughPrice.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.subscriptionFrequency.label(),
      identifier: {role: getRole(PriceWidgetComponentIds[`Frequency${priceLangType}`])},
      categoryId: CATEGORIES_IDS.pricing,
      index: 3,
      tooltipData: {
        content: elementsPanelTranslations.subscriptionFrequency.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.pricePerUnit.label(),
      identifier: {role: getRole(PriceWidgetComponentIds.PricePerUnit)},
      categoryId: CATEGORIES_IDS.pricing,
      index: 4,
      tooltipData: {
        content: elementsPanelTranslations.pricePerUnit.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.taxShippingInfo.label(),
      identifier: {role: getRole(PriceWidgetComponentIds.BreakdownBox)},
      categoryId: CATEGORIES_IDS.pricing,
      index: 5,
      tooltipData: {
        content: elementsPanelTranslations.taxShippingInfo.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.addToCart.label(),
      identifier: {role: getRole(CtaButtonsWidgetComponentIds.AddToCartBtn)},
      categoryId: CATEGORIES_IDS.ctaButtons,
      index: 0,
      tooltipData: {
        content: elementsPanelTranslations.addToCart.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.buyNow.label(),
      identifier: {role: getRole(CtaButtonsWidgetComponentIds.BuyNowBtn)},
      categoryId: CATEGORIES_IDS.ctaButtons,
      index: 1,
      tooltipData: {
        content: elementsPanelTranslations.buyNow.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.stockStatus.label(),
      identifier: {role: getRole(RootWidgetComponentIds.StockIndicator)},
      categoryId: CATEGORIES_IDS.info,
      index: 7,
      tooltipData: {
        content: elementsPanelTranslations.stockStatus.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.breadcrumbs.label(),
      identifier: {role: getRole(RootWidgetComponentIds.Breadcrumbs)},
      categoryId: CATEGORIES_IDS.navigation,
      index: 0,
      tooltipData: {
        content: elementsPanelTranslations.breadcrumbs.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.previousNext.label(),
      identifier: {role: getRole(RootWidgetComponentIds.Navigation)},
      categoryId: CATEGORIES_IDS.navigation,
      index: 1,
      tooltipData: {
        content: elementsPanelTranslations.previousNext.tooltip.text(),
      },
    },
    {
      label: elementsPanelTranslations.facebook.label(),
      identifier: {role: getRole(SocialShareWidgetComponentIds.Facebook)},
      categoryId: CATEGORIES_IDS.shareButtons,
      index: 0,
    },
    {
      label: elementsPanelTranslations.whatsapp.label(),
      identifier: {role: getRole(SocialShareWidgetComponentIds.WhatsApp)},
      categoryId: CATEGORIES_IDS.shareButtons,
      index: 1,
    },
    {
      label: elementsPanelTranslations.twitter.label(),
      identifier: {role: getRole(SocialShareWidgetComponentIds.Twitter)},
      categoryId: CATEGORIES_IDS.shareButtons,
      index: 2,
    },
    {
      label: elementsPanelTranslations.pinterest.label(),
      identifier: {role: getRole(SocialShareWidgetComponentIds.Pinterest)},
      categoryId: CATEGORIES_IDS.shareButtons,
      index: 3,
    },
    // todo: need to add 'Smart Payment' widget once implemented
  ];

  if (isMS3) {
    elementData.push({
      label: elementsPanelTranslations.discountName.label(),
      identifier: {role: getRole(PriceWidgetComponentIds.DiscountTitle)},
      categoryId: CATEGORIES_IDS.pricing,
      index: 3,
      tooltipData: {
        content: elementsPanelTranslations.discountName.tooltip.text(),
      },
    });
  }

  return elementData;
};
