/* eslint-disable func-style */
import {
  BLOCKS_PRODUCT_PAGE_WIDGET_ID,
  STORES_APP_DEF_ID,
  ECOM_APP_DEF_ID,
  rootWidgetPresetsIds,
  FedopsInteractions,
} from '../../constants/app';
import {createWidgetDefinition} from './createWidgetDefinition';
import {createSectionDefinition} from './createSectionDefinition';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {ComponentRef, FlowEditorSDK, FlowPlatformOptions, PageRef, FlowAPI} from '../editor.app.types';
import {SPECS} from '../../specs';

const DESKTOP_PRESET = rootWidgetPresetsIds['2 columns - 50:50 - left align'];
const RTL_DESKTOP_PRESET = rootWidgetPresetsIds['RTL 2 columns - 50:50 - left align'];
const MOBILE_PRESET = rootWidgetPresetsIds['1 column / mobile'];
const RTL_MOBILE_PRESET = rootWidgetPresetsIds['RTL column / mobile'];

/* istanbul ignore next */
const addPPWidgetWithPresets = (editorSDK: FlowEditorSDK, ppPageRef: PageRef | null, flowAPI: FlowAPI) => {
  const isRTL = flowAPI.environment.isSiteLanguageRTL;
  const desktopPresetToInstall = isRTL ? RTL_DESKTOP_PRESET : DESKTOP_PRESET;
  const mobilePresetToInstall = isRTL ? RTL_MOBILE_PRESET : MOBILE_PRESET;
  return editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: BLOCKS_PRODUCT_PAGE_WIDGET_ID,
    layout: {
      x: 20,
      y: 0,
      fixedPosition: false,
      width: 940,
      height: 90,
      scale: 1,
      rotationInDegrees: 0,
    },
    scopedPresets: {
      mobile: {layout: mobilePresetToInstall, style: mobilePresetToInstall},
      desktop: {
        layout: desktopPresetToInstall,
        style: desktopPresetToInstall,
      },
    },
    installationType: 'closed',
    containerRef: ppPageRef as ComponentRef,
    overriddenData: [
      {
        itemType: 'connections',
        dataItem: {
          type: 'ConnectionList',
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'productPage1',
            },
          ],
        },
        compId: 'comp-kottondk',
        isMobile: false,
      },
    ],
  });
};

export async function handleFirstInstall(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  ecomPublicApi,
  flowAPI: FlowAPI,
): Promise<void> {
  const isResponsive = options.origin.type === 'RESPONSIVE';
  // @ts-expect-error
  const isStudio = self.commonConfig?.brand === 'studio' || options.origin.subType === 'STUDIO';
  const isEditorX = isResponsive && !isStudio;

  const ppPageRef = await addProductPageAsPage(editorSDK, isEditorX, appDefId, isStudio, flowAPI);
  if (!isResponsive) {
    flowAPI.fedops.interactionStarted(FedopsInteractions.ProductPageBlocksWidgetAddition);
    await addPPWidgetWithPresets(editorSDK, ppPageRef, flowAPI);
    flowAPI.fedops.interactionEnded(FedopsInteractions.ProductPageBlocksWidgetAddition);
  }

  flowAPI.fedops.interactionStarted(FedopsInteractions.ProductPageBlocksSetStateForStoresPages);
  await ecomPublicApi.setStateForStoresPages();
  flowAPI.fedops.interactionEnded(FedopsInteractions.ProductPageBlocksSetStateForStoresPages);
}

const addProductPageAsPage = async (
  editorSDK: FlowEditorSDK,
  isEditorX: boolean,
  appDefId: string,
  isStudio: boolean,
  flowAPI: FlowAPI,
): Promise<PageRef | null> => {
  const tpaApplicationId = (await editorSDK.tpa.app.getDataByAppDefId('', ECOM_APP_DEF_ID)).applicationId;
  const isRTL = flowAPI.environment.isSiteLanguageRTL;
  const desktopPresetToInstall = isRTL ? RTL_DESKTOP_PRESET : DESKTOP_PRESET;
  const mobilePresetToInstall = isRTL ? RTL_MOBILE_PRESET : MOBILE_PRESET;
  let pageRef: PageRef | null = null;
  await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    flowAPI.fedops.interactionStarted(FedopsInteractions.ProductPageBlocksPageAddition);
    pageRef = await editorSDK.pages.add('', {
      title: 'Product Page',
      definition: {
        id: '',
        type: 'Page',
        ...(isEditorX
          ? {
              components: [
                createSectionDefinition([
                  createWidgetDefinition({
                    appDefinitionId: appDefId,
                    widgetId: BLOCKS_PRODUCT_PAGE_WIDGET_ID,
                    presetId: desktopPresetToInstall,
                    mobilePresetId: mobilePresetToInstall,
                  }),
                ]) as any,
              ],
            }
          : undefined),
        data: {
          managingAppDefId: STORES_APP_DEF_ID,
          tpaApplicationId,
          tpaPageId: PageMap.PRODUCT,
          pageUriSEO: 'product-page',
        },
        componentType: 'mobile.core.components.Page',
      },
      shouldAddMenuItem: false,
      shouldNavigateToPage: false,
    });
    flowAPI.fedops.interactionEnded(FedopsInteractions.ProductPageBlocksPageAddition);

    if (isStudio) {
      if (!flowAPI.experiments.enabled(SPECS.ProductPageBlocksNoNavigationDuringInstallation)) {
        flowAPI.fedops.interactionStarted(FedopsInteractions.ProductPageBlocksNavigateToCreatedPage);
        await editorSDK.pages.navigateTo('', {pageRef});
        flowAPI.fedops.interactionEnded(FedopsInteractions.ProductPageBlocksNavigateToCreatedPage);
      }

      flowAPI.fedops.interactionStarted(FedopsInteractions.ProductPageBlocksGetSectionRef);
      const [, sectionRef] = await editorSDK.document.components.getChildren('', {componentRef: pageRef});
      flowAPI.fedops.interactionEnded(FedopsInteractions.ProductPageBlocksGetSectionRef);

      flowAPI.fedops.interactionStarted(FedopsInteractions.ProductPageBlocksWidgetAddition);
      await editorSDK.application.appStudioWidgets.addWidget('', {
        containerRef: sectionRef,
        widgetId: BLOCKS_PRODUCT_PAGE_WIDGET_ID,
        installationType: 'closed',
        scopedPresets: {
          mobile: {layout: mobilePresetToInstall, style: mobilePresetToInstall},
          desktop: {
            layout: desktopPresetToInstall,
            style: desktopPresetToInstall,
          },
        },
        layout: undefined as any,
        layouts: {
          componentLayout: {
            type: 'ComponentLayout',
            height: {
              type: 'auto',
            },
            width: {
              type: 'auto',
            },
            minWidth: {
              type: 'px',
              value: 0,
            },
            minHeight: {
              type: 'px',
              value: 0,
            },
            hidden: false,
          },
          containerLayout: {
            type: 'GridContainerLayout',
            rows: [
              {
                type: 'fr',
                value: 1,
              },
            ],
            columns: [
              {
                type: 'MinMaxSize',
                min: {
                  type: 'px',
                  value: 0,
                },
                max: {
                  type: 'fr',
                  value: 1,
                },
              },
            ],
          },
          itemLayout: {
            alignSelf: 'stretch',
            margins: {
              left: {
                type: 'percentage',
                value: 5,
              },
              right: {
                type: 'percentage',
                value: 5,
              },
              top: {
                type: 'px',
                value: 20,
              },
              bottom: {
                type: 'px',
                value: 20,
              },
            },
            gridArea: {
              rowStart: 1,
              rowEnd: 2,
              columnStart: 1,
              columnEnd: 2,
            },
            id: '',
            justifySelf: 'stretch',
            type: 'GridItemLayout',
          },
        },
        overriddenData: [
          {
            itemType: 'connections',
            dataItem: {
              type: 'ConnectionList',
              items: [
                {
                  type: 'WixCodeConnectionItem',
                  role: 'productPage1',
                },
              ],
            },
            compId: 'comp-kottondk',
            isMobile: false,
          },
        ],
      });
      flowAPI.fedops.interactionEnded(FedopsInteractions.ProductPageBlocksWidgetAddition);
    }
  });
  return pageRef;
};
